import React from 'react';
import { Box } from '@mui/material';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';
import './Layout.scss';

const Layout = ({ children }) => {
    return (
        <Box display="flex" flexDirection="column" minHeight="100vh">
            <Header />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    width: '100%',
                }}
            >
                <Sidebar />
                <Box
                    component="div"
                    sx={{
                        flexGrow: 1,
                        padding: { xs: 1, sm: 2 },
                    }}
                >
                    {children}
                </Box>
            </Box>
            <Footer />
        </Box>
    );
};

export default Layout;

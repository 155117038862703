import React, { useEffect, useState } from 'react';
import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
    useMediaQuery,
    useTheme,
    IconButton,
} from '@mui/material';
import { fetchReportingData } from '../../service/index';
import useStore from '../../state/index';
import { formatDateWithTimeZone } from '../../utils';
import './EmployeeCheckins.scss';
import ImagePreview from '../ImagePreview/ImagePreview'; // Reuse ImagePreview component
import EmployeeDetails from '../EmployeeDetails/EmployeeDetails';
import VisibilityIcon from '@mui/icons-material/Visibility';

const EmployeeCheckins = () => {
    const [apiResponse, setApiResponse] = useState({ data: [], message: 'No data available, Click Search to load data' });
    const [filteredData, setFilteredData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [isImagePreviewOpen, setImagePreviewOpen] = useState(false);
    const [isUserDetailsOpen, setUserDetailsOpen] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState('createTs'); // Default sorting column
    const [sortDirection, setSortDirection] = useState('desc'); // Default sorting direction

    const { apiAccessToken, userTz } = useStore();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const loadDataFromServer = async () => {
        setLoading(true);
        try {
            const response = await fetchReportingData(apiAccessToken, 'EmployeeCheckins', null, null);
            setApiResponse(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadDataFromServer();
    }, [apiAccessToken]);

    const filterData = () => {
        if (apiResponse) {
            setFilteredData(apiResponse.data.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()) || String(item.phone).includes(searchQuery)));
        }
    };

    useEffect(() => {
        filterData();
    }, [searchQuery, apiResponse]);

    const handleSort = (column) => {
        const isAsc = sortBy === column && sortDirection === 'asc';
        setSortBy(column);
        setSortDirection(isAsc ? 'desc' : 'asc');
    };

    const displayedData = [...filteredData]
        .sort((a, b) => {
            const aValue = a[sortBy];
            const bValue = b[sortBy];

            if (sortBy === 'createTs') {
                return sortDirection === 'asc' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
            }

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }

            return 0;
        })
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setImagePreviewOpen(true);
    };

    const handleViewButtonClick = async (user) => {
        try {
            const response = await fetchReportingData(apiAccessToken, 'UserDetails', user.name, '', user.phone);
            if (response && response.data) {
                setSelectedUserData(response.data.sort((a, b) => new Date(b.createTs) - new Date(a.createTs)));
            } else {
                console.error('No data found in API response');
                setSelectedUserData([]);
            }
            setUserDetailsOpen(true);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    return (
        <TableContainer component={Paper} className="employee-checkins-container">
            <Typography variant="h6" className="title">
                Employee CheckIns
            </Typography>

            {loading ? (
                <div className="loading-indicator">
                    <CircularProgress />
                </div>
            ) : (
                <Table className="data-table">
                    {!isMobile && (
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <strong>Photo</strong>
                                </TableCell>
                                <TableCell sortDirection={sortBy === 'name' ? sortDirection : false}>
                                    <TableSortLabel active={sortBy === 'name'} direction={sortDirection} onClick={() => handleSort('name')}>
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <strong>Phone</strong>
                                </TableCell>
                                <TableCell sortDirection={sortBy === 'createTs' ? sortDirection : false}>
                                    <TableSortLabel active={sortBy === 'createTs'} direction={sortDirection} onClick={() => handleSort('createTs')}>
                                        Registered On
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <strong>Actions</strong>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    )}
                    <TableBody>
                        {displayedData.length > 0 ? (
                            displayedData.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        sx={{
                                            padding: '8px',
                                            textAlign: 'center',
                                            width: isMobile ? '80px' : '100px',
                                        }}
                                    >
                                        {item.imgBase64 ? (
                                            <img
                                                src={`data:image/jpeg;base64,${item.imgBase64}`}
                                                alt="thumbnail"
                                                style={{
                                                    width: isMobile ? '80px' : '100px',
                                                    height: isMobile ? '80px' : '100px',
                                                    borderRadius: '8px',
                                                    objectFit: 'cover',
                                                }}
                                                onClick={() => handleImageClick(item.imgBase64)}
                                            />
                                        ) : (
                                            'N/A'
                                        )}
                                    </TableCell>
                                    {isMobile ? (
                                        <TableCell
                                            sx={{
                                                padding: '8px',
                                                fontSize: '0.9rem',
                                                lineHeight: '1.4',
                                            }}
                                        >
                                            <strong>Name:</strong> {item.name || 'N/A'}
                                            <br />
                                            <strong>Phone:</strong> {item.phone || 'N/A'}
                                            <br />
                                            <strong>Registered On:</strong> {formatDateWithTimeZone(item.createTs, userTz)}
                                            <br />
                                            <IconButton onClick={() => handleViewButtonClick(item)} aria-label="view details" sx={{ marginTop: '8px' }}>
                                                <VisibilityIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    ) : (
                                        <>
                                            <TableCell>{item.name || 'N/A'}</TableCell>
                                            <TableCell>{item.phone || 'N/A'}</TableCell>
                                            <TableCell>{formatDateWithTimeZone(item.createTs, userTz)}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => handleViewButtonClick(item)} aria-label="view details">
                                                    <VisibilityIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={7} className="no-data">
                                    <Typography>No data available</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )}
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
            />

            {/* Image Preview */}
            <ImagePreview isOpen={isImagePreviewOpen} image={selectedImage} onClose={() => setImagePreviewOpen(false)} />

            {/* Employee Details */}
            <EmployeeDetails isOpen={isUserDetailsOpen} data={selectedUserData} onClose={() => setUserDetailsOpen(false)} timeZone={userTz} />
        </TableContainer>
    );
};

export default EmployeeCheckins;

import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import useStore from '../../state/index';
import { fetchReportingData } from '../../service/index';

const Notifications = () => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [byteSize, setByteSize] = useState(0);
    const [smsParts, setSmsParts] = useState(0);
    const { apiAccessToken, setSuccessMsg } = useStore();
    const [totalRecipients, setTotalRecipients] = useState(0);
    const [override, setOverride] = useState(false);

    const carrierFeePerMessagePart = 0.00302;
    const messagePricePerMessagePart = 0.00581;

    const totalMessages = smsParts * totalRecipients;
    const totalCarrierFee = totalMessages * carrierFeePerMessagePart;
    const totalMessagePrice = totalMessages * messagePricePerMessagePart;
    const totalCost = totalCarrierFee + totalMessagePrice;

    const loadDataFromServer = async () => {
        try {
            const response = await fetchReportingData(apiAccessToken, 'Registration', '');
            const match = response.message.match(/\[\d+\]/);
            if (match) {
                const number = parseInt(match[0].replace(/[\[\]]/g, ''), 10);
                setTotalRecipients(number);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        loadDataFromServer();
    }, [apiAccessToken]);

    const isGsm7 = (text) => {
        const gsm7Regex = /^[\x00-\x7F]*$/;
        return gsm7Regex.test(text);
    };

    const calculateByteSize = (text) => {
        if (isGsm7(text)) {
            return text.length;
        }
        return [...text].reduce((size, char) => size + (char.length > 1 ? 2 : 2), 0);
    };

    const calculateSmsParts = (text) => {
        const isGsm = isGsm7(text);
        const charLimit = isGsm ? 160 : 70;
        const totalChars = text.length;
        return totalChars > 0 ? Math.ceil(totalChars / charLimit) : 0;
    };

    const handleSend = async () => {
        if (message.trim().length === 0) {
            setError('Message cannot be empty.');
            return;
        }

        if (smsParts > 1 && !override) {
            setError('Message exceeds 1 part. Check override to send.');
            return;
        }

        setOpenConfirm(true);
    };

    const confirmSend = async () => {
        setError('');
        setIsSending(true);
        setOpenConfirm(false);

        try {
            const response = await fetch('https://api.quikwish.com/notifications', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${apiAccessToken}`,
                },
                body: JSON.stringify({ text: message }),
            });

            if (!response.ok) {
                throw new Error('Failed to send notification.');
            }

            setMessage('');
            setSuccessMsg('Notification sent successfully!');
        } catch (err) {
            setError(err.message);
        } finally {
            setIsSending(false);
        }
    };

    const cancelSend = () => {
        setOpenConfirm(false);
    };

    const handleMessageChange = (e) => {
        const newMessage = e.target.value;
        setMessage(newMessage);

        const size = calculateByteSize(newMessage);
        setByteSize(size);

        const parts = calculateSmsParts(newMessage);
        setSmsParts(parts);

        if (parts > 1 && !override) {
            setError('Message exceeds the 140-byte SMS limit.');
        } else if (parts === 1) {
            setError('Message nearing the byte limit.');
        } else {
            setError('');
        }
    };

    return (
        <Box
            sx={{
                maxWidth: 500,
                margin: '20px auto',
                padding: 2,
                border: '1px solid #ddd',
                borderRadius: 2,
                backgroundColor: '#f9f9f9',
            }}
        >
            <TextField
                fullWidth
                multiline
                rows={4}
                value={message}
                onChange={handleMessageChange}
                placeholder="Type your message here (max 160 bytes)..."
                variant="outlined"
                error={!!error}
                sx={{ marginBottom: 2 }}
            />
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                    <Typography variant="caption" color={byteSize > 160 ? 'error.main' : byteSize > 140 ? 'warning.main' : 'text.secondary'}>
                        {`${byteSize}/160 bytes`}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" display="block">
                        {`SMS Parts: ${smsParts}`}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" display="block">
                        {`Total Recipients: ${totalRecipients}`}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" display="block">
                        {`Total Messages: ${totalMessages}`}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                        {`Total Cost: $${totalCost.toFixed(5)}`}
                    </Typography>
                </Box>
                <FormControlLabel control={<Checkbox checked={override} onChange={(e) => setOverride(e.target.checked)} color="primary" />} label="Override message size limit" sx={{ marginTop: 2 }} />
                <Button variant="contained" color="primary" onClick={handleSend} disabled={isSending || message.trim().length === 0 || (smsParts > 1 && !override)}>
                    {isSending ? 'Sending...' : 'Send'}
                </Button>
            </Box>

            <Dialog open={openConfirm} onClose={cancelSend}>
                <DialogTitle>Confirm Notification</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will send SMS messages to all registered users in this store. Are you sure you want to continue?
                        <br />
                        <br />
                        <strong>Details:</strong>
                        <br />- SMS Parts: {smsParts}
                        <br />- Recipients: {totalRecipients}
                        <br />- Total Messages: {totalMessages}
                        <br />- Carrier Fee: ${totalCarrierFee.toFixed(5)}
                        <br />- Message Price: ${totalMessagePrice.toFixed(5)}
                        <br />- <strong>Total Cost: ${totalCost.toFixed(5)}</strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelSend} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={confirmSend} color="primary" autoFocus>
                        Yes, Send
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Notifications;

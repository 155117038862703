import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery, useTheme } from '@mui/material';
import './UserDetails.scss';
import { formatDateWithTimeZone } from '../../utils';

const UserDetails = ({ isOpen, data, onClose, timeZone }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detects mobile screens

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>User Details</DialogTitle>
            <DialogContent dividers>
                {data.length > 0 ? (
                    <Table>
                        {!isMobile && (
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong>Photo</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Name</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Phone</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Denomination</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Action</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Create Date</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        )}
                        <TableBody>
                            {data.map((item, index) => (
                                <TableRow key={index}>
                                    {isMobile ? (
                                        <>
                                            <TableCell
                                                colSpan={6}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '4px', // Reduce spacing between rows
                                                    padding: '8px', // Compact padding
                                                    fontSize: '0.9rem', // Smaller font size
                                                    lineHeight: '1.2', // Tighten line height
                                                }}
                                            >
                                                {item.imgBase64 ? (
                                                    <img
                                                        src={`data:image/jpeg;base64,${item.imgBase64}`}
                                                        alt="thumbnail"
                                                        style={{
                                                            width: '70px', // Smaller image width
                                                            height: '70px', // Smaller image height
                                                            borderRadius: '8px',
                                                            objectFit: 'cover',
                                                            marginBottom: '8px', // Slight spacing below the image
                                                        }}
                                                    />
                                                ) : (
                                                    <div
                                                        style={{
                                                            width: '70px',
                                                            height: '70px',
                                                            borderRadius: '8px',
                                                            backgroundColor: '#e0e0e0',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            fontSize: '0.8rem',
                                                            color: '#757575',
                                                            marginBottom: '8px', // Slight spacing below placeholder
                                                        }}
                                                    >
                                                        No Photo
                                                    </div>
                                                )}
                                                <div>
                                                    <strong>Name:</strong> {item.name || 'N/A'}
                                                </div>
                                                <div>
                                                    <strong>Phone:</strong> {item.phone || 'N/A'}
                                                </div>
                                                <div>
                                                    <strong>Denomination:</strong> {item.action === 'Check Out' ? `$${item.cashOutAmount}` : `$${item.denomination || '0'}`}
                                                </div>
                                                <div>
                                                    <strong>Action:</strong>{' '}
                                                    <span
                                                        style={{
                                                            color: item.action === 'Registration' ? 'green' : item.action === 'Check In' ? 'orange' : item.action === 'Check Out' ? 'red' : 'gray',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {item.action || 'N/A'}
                                                    </span>
                                                </div>
                                                <div>
                                                    <strong>Create Date:</strong> {formatDateWithTimeZone(item.createTs, timeZone)}
                                                </div>
                                            </TableCell>
                                        </>
                                    ) : (
                                        <>
                                            <TableCell>
                                                {item.imgBase64 ? (
                                                    <img
                                                        src={`data:image/jpeg;base64,${item.imgBase64}`}
                                                        alt="thumbnail"
                                                        style={{
                                                            width: '100px',
                                                            height: '100px',
                                                            borderRadius: '8px',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                ) : (
                                                    <div
                                                        style={{
                                                            width: '100px',
                                                            height: '100px',
                                                            borderRadius: '50%',
                                                            backgroundColor: '#e0e0e0',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            fontSize: '0.8rem',
                                                            color: '#757575',
                                                        }}
                                                    >
                                                        No Photo
                                                    </div>
                                                )}
                                            </TableCell>
                                            <TableCell>{item.name || 'N/A'}</TableCell>
                                            <TableCell>{item.phone || 'N/A'}</TableCell>
                                            <TableCell>{item.action === 'Check Out' ? `$${item.cashOutAmount}` : `$${item.denomination || '0'}`}</TableCell>
                                            <TableCell
                                                style={{
                                                    color: item.action === 'Registration' ? 'green' : item.action === 'Check In' ? 'orange' : item.action === 'Check Out' ? 'red' : 'gray',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {item.action || 'N/A'}
                                            </TableCell>
                                            <TableCell>{formatDateWithTimeZone(item.createTs, timeZone)}</TableCell>
                                        </>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <CircularProgress style={{ display: 'block', margin: 'auto' }} />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserDetails;

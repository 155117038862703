import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Grid, IconButton, InputAdornment, MenuItem, Paper, Select, TextField, useMediaQuery, useTheme, FormControl, InputLabel } from '@mui/material';
import React from 'react';
import './Search.scss';

const Search = ({ fromDate, setFromDate, action, setAction, handleSearchBtnClick, searchQuery, setSearchQuery, shifts, selectedShift, setSelectedShift, isShowCheckOut, userRole }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detects screen size below 'sm'

    const handleSearchClick = () => {
        setSearchQuery(''); // Clear the filter field
        handleSearchBtnClick(); // Trigger the search button action
    };

    const handleActionChange = (e) => {
        setAction(e.target.value); // Update the action state
    };

    return (
        <Paper elevation={3} sx={{ padding: 2, marginY: 2 }}>
            <Grid
                container
                spacing={isMobile ? 1 : 2}
                alignItems="center"
                sx={{
                    width: '100%',
                    maxWidth: isMobile ? '100%' : '1200px', // Full width on mobile, restricted on desktop
                    margin: '0 auto', // Center align for desktop
                }}
            >
                {/* Date Field */}
                <Grid item xs={12} sm={1.6}>
                    <TextField
                        label="Date"
                        type="date"
                        variant="outlined"
                        onChange={(e) => setFromDate(e.target.value)}
                        value={fromDate}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        inputProps={{
                            max: new Date().toISOString().split('T')[0],
                        }}
                    />
                </Grid>

                {/* Filter By Select */}
                <Grid item xs={12} sm={1.6}>
                    <FormControl fullWidth>
                        <InputLabel id="action-label">Action</InputLabel>
                        <Select labelId="action-label" label="Action" value={action} onChange={handleActionChange} fullWidth>
                            {userRole !== 'Employee' && <MenuItem value="Registration">Registrations</MenuItem>}
                            <MenuItem value="Check_In">Check Ins</MenuItem>
                            {isShowCheckOut && <MenuItem value="Check_Out">Check Outs</MenuItem>}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Shifts Dropdown */}
                <Grid item xs={12} sm={2}>
                    <FormControl fullWidth>
                        <InputLabel id="shift-label">Shift</InputLabel>
                        <Select
                            labelId="shift-label"
                            label="Shift"
                            value={selectedShift || ''}
                            onChange={(e) => setSelectedShift(e.target.value)} // Pass selected shift back
                        >
                            {shifts.map((shift) => {
                                const formatTime = (hour) => {
                                    const period = hour >= 12 ? 'PM' : 'AM';
                                    const formattedHour = hour % 12 || 12; // Convert 0 to 12 for midnight
                                    return `${formattedHour}:00 ${period}`;
                                };

                                return (
                                    <MenuItem key={shift.order} value={shift}>
                                        {`${formatTime(shift.startHour)} - ${formatTime(shift.endHour)}`}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Search Button or Icon */}
                <Grid item xs={isMobile ? 12 : 2}>
                    {isMobile ? (
                        <IconButton
                            color="primary"
                            onClick={handleSearchClick}
                            size="large"
                            sx={{
                                border: '1px solid rgba(0,0,0,0.2)',
                                borderRadius: '8px',
                                width: '100%', // Make the button take full width on mobile
                                minHeight: '3rem', // Consistent height
                            }}
                        >
                            <SearchIcon />
                        </IconButton>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSearchClick}
                            fullWidth
                            sx={{
                                minHeight: '3rem', // Consistent height
                            }}
                        >
                            Search
                        </Button>
                    )}
                </Grid>
                {/* Filter Text Field */}
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Filter by Name or Phone"
                        variant="outlined"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        value={searchQuery}
                        fullWidth
                        InputProps={{
                            endAdornment: searchQuery && (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setSearchQuery('')}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Search;

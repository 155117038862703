import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import useStore from '../../state/index';

const EmployeeDetails = ({ isOpen, data, onClose }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detects mobile screens
    const { userTz } = useStore();

    const pairCheckInsAndOuts = (data) => {
        const validData = data.filter((item) => item.action === 'Check In' && item.denomination === 0);
        const sortedData = validData.sort((a, b) => new Date(a.currentCheckInDt) - new Date(b.currentCheckInDt));

        const pairedData = [];
        for (let i = 0; i < sortedData.length - 1; i += 2) {
            const checkIn = sortedData[i];
            const checkOut = sortedData[i + 1];
            const checkInTime = new Date(checkIn.currentCheckInDt);
            const checkOutTime = new Date(checkOut.currentCheckInDt);

            pairedData.push({
                checkIn,
                checkOut,
                diffInHours: ((checkOutTime - checkInTime) / (1000 * 60 * 60)).toFixed(2),
            });
        }

        if (sortedData.length % 2 !== 0) {
            const lastUnpaired = sortedData[sortedData.length - 1];
            pairedData.push({
                checkIn: lastUnpaired,
                checkOut: null,
                diffInHours: 0,
            });
        }

        return pairedData.reverse();
    };

    const pairedData = pairCheckInsAndOuts(data);
    const employeeName = data[0]?.name || 'Employee';

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>Employee Check-In Details</DialogTitle>
            <DialogContent dividers>
                <div style={{ marginBottom: '20px' }}>
                    <h2 style={{ margin: 0, textAlign: 'center' }}>{employeeName}</h2>
                </div>
                {pairedData.length > 0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    <strong>Photo</strong>
                                </TableCell>
                                <TableCell align="center">
                                    <strong>{isMobile ? 'Details' : 'Date & Time'}</strong>
                                </TableCell>
                                {!isMobile && (
                                    <TableCell align="center">
                                        <strong>Clocked (hours)</strong>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pairedData.map((pair, index) => {
                                const { checkIn, checkOut, diffInHours } = pair;

                                if (isMobile) {
                                    // Mobile view: Combine all details into a single column
                                    return (
                                        <TableRow key={index}>
                                            <TableCell align="center" sx={{ verticalAlign: 'top', padding: '8px' }}>
                                                {checkIn.imgBase64 ? (
                                                    <img
                                                        src={`data:image/jpeg;base64,${checkIn.imgBase64}`}
                                                        alt={`${checkIn.name || 'Employee'}`}
                                                        style={{
                                                            width: '80px',
                                                            height: '80px',
                                                            borderRadius: '50%',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                ) : (
                                                    <div
                                                        style={{
                                                            width: '80px',
                                                            height: '80px',
                                                            borderRadius: '50%',
                                                            backgroundColor: '#e0e0e0',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            fontSize: '0.8rem',
                                                            color: '#757575',
                                                        }}
                                                    >
                                                        No Photo
                                                    </div>
                                                )}
                                            </TableCell>
                                            <TableCell align="left" sx={{ padding: '8px', fontSize: '0.9rem', lineHeight: '1.5' }}>
                                                <strong>
                                                    <LoginIcon style={{ color: 'green', verticalAlign: 'middle', fontSize: '1.2rem' }} /> Check In:
                                                </strong>{' '}
                                                {format(new Date(checkIn.currentCheckInDt), 'EEEE, dd MMM yyyy, hh:mm:ss a')}
                                                <br />
                                                {checkOut && (
                                                    <>
                                                        <strong>
                                                            <LogoutIcon style={{ color: 'red', verticalAlign: 'middle', fontSize: '1.2rem' }} /> Check Out:
                                                        </strong>{' '}
                                                        {format(new Date(checkOut.currentCheckInDt), 'EEEE, dd MMM yyyy, hh:mm:ss a')}
                                                        <br />
                                                    </>
                                                )}
                                                <strong>Hours Clocked:</strong> {diffInHours} hours
                                            </TableCell>
                                        </TableRow>
                                    );
                                }

                                // Web view: Original layout with separate columns
                                return (
                                    <React.Fragment key={index}>
                                        <TableRow>
                                            <TableCell rowSpan={2} align="center">
                                                {checkIn.imgBase64 ? (
                                                    <img
                                                        src={`data:image/jpeg;base64,${checkIn.imgBase64}`}
                                                        alt={`${checkIn.name || 'Employee'}`}
                                                        style={{
                                                            width: '100px',
                                                            height: '100px',
                                                            borderRadius: '50%',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                ) : (
                                                    <div
                                                        style={{
                                                            width: '100px',
                                                            height: '100px',
                                                            borderRadius: '50%',
                                                            backgroundColor: '#e0e0e0',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            fontSize: '0.8rem',
                                                            color: '#757575',
                                                        }}
                                                    >
                                                        No Photo
                                                    </div>
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {checkOut && (
                                                    <>
                                                        <LogoutIcon style={{ color: 'red', fontSize: '1.5rem', verticalAlign: 'middle' }} />{' '}
                                                        <span>{format(new Date(checkOut.currentCheckInDt), 'EEEE, dd MMM yyyy')}</span>
                                                        <span
                                                            style={{
                                                                fontSize: '1.5rem',
                                                                fontWeight: 'bold',
                                                                display: 'block',
                                                            }}
                                                        >
                                                            {format(new Date(checkOut.currentCheckInDt), 'hh:mm:ss a')}
                                                        </span>
                                                    </>
                                                )}
                                            </TableCell>
                                            <TableCell rowSpan={2} align="center" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                                {diffInHours} hours
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">
                                                <LoginIcon style={{ color: 'green', fontSize: '1.5rem', verticalAlign: 'middle' }} />{' '}
                                                <span>{format(new Date(checkIn.currentCheckInDt), 'EEEE, dd MMM yyyy')}</span>
                                                <span
                                                    style={{
                                                        fontSize: '1.5rem',
                                                        fontWeight: 'bold',
                                                        display: 'block',
                                                    }}
                                                >
                                                    {format(new Date(checkIn.currentCheckInDt), 'hh:mm:ss a')}
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                ) : (
                    <p>No details available</p>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EmployeeDetails;
